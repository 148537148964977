import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/containers.style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Navbar';
import Footer from '../containers/Footer';
import Seo from 'components/seo';

const keywords = ['brainmantis', 'web development',
  'mobile app development', 'devops', 'ui/ux',
  'brainmantis.com', 'brain mantis', 'react developer',
  'software company', 'software design', 'mobile development',
  'android app development software', 'ios app development software',
  'software maker'];

const Layout = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo title="Brainmantis: Web development & DevOps consultancy" keywords={keywords}/>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar {...props} />
            </DrawerProvider>
          </Sticky>
          {props.children}
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default Layout;
