import Link from 'common/components/Link';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import { graphql, useStaticQuery } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { paperPlane } from 'react-icons-kit/fa/paperPlane';
import { ic_phone } from 'react-icons-kit/md/ic_phone';
import { ic_place } from 'react-icons-kit/md/ic_place';
import React from 'react';
import {
  FooterBottom,
  SocialLinks,
  ContactInfo,
  InfoItem,
  ContactSection
} from './footer.style';
import Heading from 'common/components/Heading';

const Footer_Bottom = (props) => {
  const data = useStaticQuery(graphql`
  query {
		dataJson {
			footerBottom {
				copyright
				socialLinks {
					icon {
						publicURL
					}
					id
					label
					link
				}
        contactInfo {
          address
          email
          openingTime
          phone
          title
        }
			}
		}
  }
  `);

  const { dataJson: footer } = data;

  const items = footer?.footerBottom;

  const { copyright, socialLinks, contactInfo } = items || {}

  return (
    <Container {...props}>
      <FooterBottom>
        <ContactSection >
          <ContactInfo>
            <Heading as="h4" content={contactInfo?.title} font />
            <InfoItem>
              <Icon icon={ic_place} size={24} />
              <Text content={contactInfo?.address} />
            </InfoItem>
            <InfoItem>
              <Icon icon={ic_phone} size={26} className="phone-icon" />
              <div>
                <Text
                  className="phone-number"
                  content={contactInfo?.phone}
                />
                <Text content={contactInfo?.openingTime} />
              </div>
            </InfoItem>
            <InfoItem>
              <Icon icon={paperPlane} size={22} />
              <Text content={contactInfo?.email} />
            </InfoItem>
          </ContactInfo>
          <SocialLinks>
            {socialLinks?.length ?
              <ul>
                {socialLinks?.map((item, index) => (
                  <li key={index}>
                    <Link href={item?.link}>
                      <img src={item?.icon?.publicURL} alt={item?.label} />
                    </Link>
                  </li>
                ))}
              </ul>
              :
              ""
            }
          </SocialLinks>
        </ContactSection>
        <Text content={copyright} />
      </FooterBottom>
    </Container>
  );
};

export default Footer_Bottom;
