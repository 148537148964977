import React from 'react';
import Banner from '../containers/Banner';
import FeatureSection from '../containers/Features';
import TimelineSection from '../containers/TimelineSection';
import ProcessSection from '../containers/Process';
import SkillSection from '../containers/Skill';
import CallToAction from '../containers/CallToAction';

import Layout from '../components/layout';

const Home = (props) => {
  return (
    <Layout {...props}>
      <Banner />
      <FeatureSection />
      <SkillSection />
      <ProcessSection />
      <TimelineSection />
      <CallToAction />
    </Layout>
  );
}

export default Home;
