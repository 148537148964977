import styled from 'styled-components';

const Process = styled.div`
  position: relative;

  & .tech-item {
    transition: all .2s;
    margin-left: 0px;
    &:hover {
      color: #00c1a2;
      margin-left: 10px;
      span {
        color: inherit;
      }
    }
  }
`;

export default Process;
