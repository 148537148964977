import React from 'react';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/ContainerTwo';
import Section, {
  ContentWrapper,
  BannerContent,
  Subscribe,
} from './banner.style';
import Lottie from 'react-lottie';
import banner from "../../images/lotties/banner.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const bannerLottie = { ...defaultOptions, animationData: banner }

const Banner = () => {
  return (
    <Section id="home">
      <Container>
        <ContentWrapper>
          <BannerContent>
            <Heading
              as="h1"
              content="Redefine your Internet Reach and grow your Exciting Business ideas"
            />

            <Text
              className="banner-caption"
              content="Expand your digital footprint through our services."
            />

            <Subscribe>
              <a href="mailto:contact@brainmantis.com">
                <Button title="DISCUSS WITH US" type="submit" />
              </a>
            </Subscribe>

          </BannerContent>
          <div className="banner-lottie">
            <Lottie options={bannerLottie} height={'65%'} />
          </div>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
