export const data = {
  navItems: [
    {
      "label": "Home",
      "path": "#home",
      "offset": "70"
    },
    {
      "label": "Service",
      "path": "#service",
      "offset": "70"
    },
    {
      "label": "Project Flow",
      "path": "#project-flow",
      "offset": "70"
    },
    {
      "label": "Contact Us",
      "path": "#contact-us",
      "offset": "70"
    },
    
  ]
};
